import styled from "styled-components"

const PageHeader = styled.h1`
  font-family: "alternategothic2_btregular";
  color: white;
  text-transform: uppercase;
  font-size: 24px;
  position: absolute;
  top: 19%;
  left: 5%;
  text-decoration: ${props => props.link && "underline"};
  cursor: ${props => props.link && "pointer"};
  transition: 0.2s ease-in-out;
  display: ${props => props.hide && "none"};

  :hover {
    transform: ${props => props.link && "translateX(-3px)"};
  }
`

export default PageHeader
