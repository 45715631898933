import React, { Component } from "react"
import Layout from "../components/layout"
import PageHeader from "../components/pageHeader"
import styled from "styled-components"
import CommercialItem from "../components/commercial-item"
import FilmItem from "../components/film-item"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const Grid = styled.div`
  margin-top: 40vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`

class FilmsPage extends Component {
  render() {
    const commercialData = this.props.data.allWordpressPost.edges
    return (
      <Layout>
        <SEO title="Films" />
        <PageHeader>Films</PageHeader>
        <Grid>
          {commercialData.map((commercial, index) => (
            <FilmItem
              key={index}
              link={commercial.node.slug}
              cover={commercial.node.acf.cover ? commercial.node.acf.cover.source_url : ''}
              title={commercial.node.acf.project_title}
              caption={commercial.node.acf.project_theme}
              fluid={commercial.node.acf.cover ? commercial.node.acf.cover.localFile.childImageSharp.fluid : false}
            />
          ))}
        </Grid>
      </Layout>
    )
  }
}

export default FilmsPage

export const FilmPageQuery = graphql`
  query filmQuery($category: String = "Films") {
    allWordpressPost(
      sort: {fields: date, order: DESC}
      filter: { categories: { elemMatch: { name: { eq: $category } } } }
    ) {
      edges {
        node {
          title
          slug
          acf {
            project_title
            cover {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxHeight: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            # cover_image
            # project_theme
          }
        }
      }
    }
  }
`
