import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import Img from "gatsby-image"

const Container = styled.div`
  flex-basis: 20%;
  height: 200px;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  /*Media Queries*/
  @media (max-width: 1000px) {
    /* flex-basis: 25%; */
    flex-basis: 33.33%;
  }

  @media (max-width: 850px) {
    flex-basis: 33.33%;
  }
  @media (max-width: 600px) {
    flex-basis: 50%;
  }
  @media (max-width: 500px) {
    flex-basis: 100%;
  }
`
const Cover = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.6s ease-out;
  transform: scale(1.1);

  ${Container}:hover & {
    filter: brightness(50%);
    transform: scale(1);
  }
`
const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  padding: 0 5px;
  transform: translate(-50%, -50%);
`
const Title = styled.h3`
  font-family: "alternategothic2_btregular";
  margin-bottom: 5px;
  color: white;
  text-transform: uppercase;
  font-size: 30px;
  padding: 0;
`
const Caption = styled.p`
  /* font-weight: 600; */
  font-size: 12px;
  font-family: "Lato";
  margin-bottom: 0;
  opacity: 0;
  letter-spacing: 1.1px;
  transition: 0.3s ease-in-out;
  transform: scale(1.1);

  ${Container}:hover & {
    opacity: 1;
    letter-spacing: 0;
    transform: scale(1);
  }
`
const ImgWrapper = styled.div`
  height: 100%;
  transition: 0.3s ease-out;
  transform: scale(1.1);

  ${Container}:hover & {
    filter: brightness(65%);
    transform: scale(1);
  }
`

const CommercialItem = props => (
  <Container onClick={() => navigate(`/commercials/${props.link}`)}>
    <ImgWrapper>
    {props.fluid ?
      <Img
      style={{
        width: "100%",
        height: "100%",
        margin: 0,
        objectFit: "cover",
        transform: "scale(1)",
        ":hover": {
          transform: "scale(1.)",
        },
      }}
      fadeIn
      objectFit="cover"
      fluid={props.fluid}
      />
      :
      <Cover src={props.cover ? props.cover.source_url : ''} />
    }
    </ImgWrapper>
    
    <TextContainer>
      <Title>{props.title}</Title>
      <Caption>{props.caption}</Caption>
    </TextContainer>
  </Container>
)

export default CommercialItem
